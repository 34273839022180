<template>
    <div >
        <div style="position:relative" class="d-flex flex-column justify-content-center align-items-center mt-25 mb-25">
            <span class="mb-15"><b>Profile Picture</b></span>
            <img :src="$url+'/images/users/' + form.profilePictureUrl + '?m=' + Date.now()" v-if="form.profilePictureUrl" alt="hi" class="dashboard__picture">
            <input type="file"
                style="position:absolute; opacity:0;pointer-events: none;"
                @input="changeFile"
                ref="imageInput"
                accept="image/png, image/jpeg, image/jpg">
            <div class="mt-20">
                <button class="e-btn mr-10" @click="browse"> <span></span>Browse</button>
                <button class="e-btn mr-10" v-if="file && file.length != 0" @click="uploadPicture"> <span></span>Upload</button>
                <button class="e-btn" v-if="form.profilePictureUrl" @click="()=>{file='';uploadPicture();}"><span></span>Remove</button>
            </div>
        </div>
        <form class="dashboard__form-input mb-30" @submit.prevent="submit">
            <input type="text" placeholder="Email" v-model='form.email' required>
            <input type="text" placeholder="First Name" required v-model='form.firstName'>
            <input type="text" placeholder="Last Name" required v-model='form.lastName' >
            <input type="text" placeholder="Phone Number" required v-model='form.phoneNumber'>
            <div class="dashboard__sort-inner">
                <h5>Country</h5>
                <select v-model="form.country" required>
                    <option value="jordan">Jordan - الأردن</option>
                    <option value="palestine">Palestine - فلسطين</option>
                    <option value="kuwait">Kuwait - الكويت</option>
                    <option value="iraq">Iraq - العراق</option>
                    <option value="saudiArabia">Saudi Arabia - السعودية</option>
                    <option value="uae">United Arab Emirates - الإمارات</option>
                    <option value="qatar">Qatar - قطر</option>
                    <option value="bahrain">Bahrain - البحرين</option>
                    <option value="oman">Oman - عُمان</option>
                    <option value="syria">Syria - سوريا</option>
                    <option value="lebanon">Lebanon - لبنان</option>
                    <option value="other">Other - أُخرى</option>
                </select>
            </div>
            <div class="dashboard__sort-inner">
                <h5>University</h5>
                <select v-model="form.university" required>
                    <option value="ju">Jordan University - الأردنية</option>
                    <option value="just">Jordan University of Science and Technology  - العلوم و التكنولوجيا</option>
                    <option value="yarmouk">Yarmouk University - اليرموك</option>
                    <option value="balqa">AlBalqa Applied University - البلقاء</option>
                    <option value="hashemite">Hashemite University - الهاشمية</option>
                    <option value="mutah">Mutah university - مؤتة</option>
                    <option value="other">Other - أُخرى</option>
                </select>
            </div>
            <div class="dashboard__sort-inner">
                <h5>Field of Study</h5>
                <select v-model="form.fieldOfStudy" required>
                    <option value="medicine">Medicine - الطب</option>
                    <option value="dentistery">Dentistery  - طب الاسنان</option>
                    <option value="pharmacy">Pharmacy - صيدلة</option>
                    <option value="nursing">Nursing - التمريض</option>
                    <option value="rehabilitation">Rehabilitation - التأهيل</option>
                    <option value="other">Other - أُخرى</option>
                </select>
            </div>
            <div class="d-flex align-items-center">
                <h5>Sex: </h5>
                    <br>
                    <input type="radio" id="maleRadio" value='1' v-model="form.sex" required/>
                    <label for="maleRadio">Male</label>

                    <input type="radio" id="maleRadio" value="2" v-model="form.sex" required/>
                    <label for="maleRadio">Female</label>
            </div>
            <div class="mt-25" v-if="details.isInstructor">
                <h3>Instructor</h3>
                <input type="text" placeholder="Facebook" v-model='form.facebook'>
                <input type="text" placeholder="Instagram" v-model='form.instagram'>
                <input type="text" placeholder="Whatsapp" v-model='form.whatsapp'>
            </div>
            <button type="submit" class="e-btn">Save</button>
            
        </form>
    </div>
</template>

<script>
/*
need to change image src cache because some time link is same  but content is different
*/
import api from '../../api'
export default {
    name:"UserDetails",
    props:['details'],
    emits:['toast'],
    data(){
        return {
            form:{},
            file:[]
        }
    },
    methods:{
        browse(){
            this.$refs.imageInput.click();
        },
        changeFile(e){
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;

            this.file = files[0]
        },
        uploadPicture(){
            const formData = new FormData();
            formData.append('thumb', this.file);
            
            fetch(`${api.Url}/api/users/${this.details._id}/change-picture`,{
                method:"POST",
                headers: api.headers(true, false),
                body:formData
            })
            .then((res)=>res.json())
            .then(res=>{
                console.log(res)
                if (res.success){
                    this.form.profilePictureUrl = res.profilePictureUrl
                    this.file = []
                    this.$emit('toast', true)
                } else {
                    this.$emit('toast', false)
                }
            })
        },
        submit(){
            fetch(`${api.Url}/api/users/${this.details._id}/`,{
                method:"POST",
                headers: api.headers(),
                body:JSON.stringify(this.form)
            })
            .then(res=>res.json())
            .then(res => {
                if (res.success){
                    this.$emit('toast', true)
                } else {
                    this.$emit('toast', false)
                }
            })
        }
    },
    watch:{
        details:{
            handler(newVal){
                this.form = JSON.parse(JSON.stringify(newVal))
            },
            deep:true,
            setImmediate:true
        }
    },
    mounted(){
        this.form = JSON.parse(JSON.stringify(this.details))
    }
}
</script>

<style>

</style>