<template>
<section class="hero__area hero__height d-flex align-items-center grey-bg-2 p-relative">
   <div class="hero__shape">
      <img class="hero-1-circle" src="/img/shape/hero/hero-1-circle.png" alt="">
      <img class="hero-1-circle-2" src="/img/shape/hero/hero-1-circle-2.png" alt="">
      <img class="hero-1-dot-2" src="/img/shape/hero/hero-1-dot-2.png" alt="">
   </div>
   <div class="container">
      <div class="hero__content-wrapper mt-90">
         <div class="row align-items-center">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
               <div class="hero__content p-relative z-index-1">
                  <h3 class="hero__title">
                     <span></span>
                     <span class="yellow-shape">Our services:<img src="/img/shape/yellow-bg.png" alt="yellow-shape"> </span> 
                  </h3>
                     <p class="mb-1">- High quality online courses.</p>
                     <p class="mb-1">- Private lessons in Arabic or in English."</p>
                     <p class="mb-3">- Live courses for large groups.</p>
                     <p class="mb-1">We have high quality Tutors for all subjects who will make sure you get the highest marks.</p>
                     <p class="mb-3"> <b> For more info: {{phone?.phone1}} {{phone?.phone2}}</b></p>
                     <router-link to="/courses" class="e-btn">View all course</router-link>
               </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
               <div class="hero__thumb d-flex p-relative">
                  <div class="hero__thumb-shape">
                     <img class="hero-1-dot" src="/img/shape/hero/hero-1-dot.png" alt="">
                     <img class="hero-1-circle-3" src="/img/shape/hero/hero-1-circle-3.png" alt="">
                     <img class="hero-1-circle-4" src="/img/shape/hero/hero-1-circle-4.png" alt="">
                  </div>
                  <div class="hero__thumb-big mr-30">
                     <img src="/img/hero/hero-1.jpg" alt="">
                     <div class="hero__quote hero__quote-animation">
                        <h4>"An investment in knowledge pays the best interest."</h4>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
  name: "HeroSection",
  props:['phone']
};
</script>